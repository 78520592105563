import { createStore } from 'vuex';
import Home from '@/store/Home/index';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Home
  }
})
