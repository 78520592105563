import * as API from '@/api/index';
export default ({
    namespaced: true,
    state: {},
    actions: {
        call({ commit }, data) {
            try {
                const res = API.getCallCount(data);
                commit('HandleCall', res.data);
                return res.data;
            } catch (err) {
                console.log(err);
                return err;
            }
        }
    },
    mutations: {
        HandleCall(state, payload) {
            console.log(payload);

        }
    },
    getters: {}
})