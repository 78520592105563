import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      title: '登入頁面'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: { title: '消安動力系統', logo: true },
    children: [
      {
        path: '',
        name: 'homepage',
        component: () => import('@/views/Home/index.vue'),
        meta: { title: '消安動力系統', logo: true }
      },
      {
        path: '/handle-call',
        name: 'handle-call',
        component: () => import('@/views/Home/callhandle.vue'),
        meta: { title: '處理', logo: false }
      },
      {
        path: '/handle-fault',
        name: 'handle-fault',
        component: () => import('@/views/Home/faulthandle.vue'),
        meta: { title: '處理', logo: false }
      },
      {
        path: '/handle-danger',
        name: 'handle-danger',
        component: () => import('@/views/Home/dangerhandle.vue'),
        meta: { title: '隱患處理', logo: false }
      },
      {
        path: '/unnormal-center',
        name: 'unnormal',
        component: () => import('@/views/Unnormal/index.vue'),
        meta: { title: '消安動力系統', logo: false }
      },
      {
        path: '/call-detail',
        name: 'call-detail',
        component: () => import('@/views/Unnormal/detailCall.vue'),
        meta: { title: '詳情', logo: false }
      },
      {
        path: '/call-detail-record',
        name: 'call-detail-record',
        component: () => import('@/views/Unnormal/callrecord.vue'),
        meta: { title: '報警次數', logo: false }
      },
      {
        path: '/history',
        name: 'history',
        component: () => import('@/views/Unnormal/history.vue'),
        meta: { title: '歷史數據', logo: false }
      },
      {
        path: '/catch',
        name: 'catch',
        component: () => import('@/views/Unnormal/catch.vue'),
        meta: { title: '接入記錄', logo: false }
      },
      {
        path: '/fault-detail',
        name: 'fault-detail',
        component: () => import('@/views/Unnormal/detailFault.vue'),
        meta: { title: '詳情', logo: false }
      },
      {
        path: '/device-oversee',
        name: 'device',
        component: () => import('@/views/DeviceOversee/index.vue'),
        meta: { title: '電器監管', logo: false }
      },
      {
        path: '/device-oversee-detail',
        name: 'device-detail',
        component: () => import('@/views/DeviceOversee/detail.vue'),
        meta: { title: '安全用電', logo: false }
      },
      {
        path: '/device-oversee-history',
        name: 'device-history',
        component: () => import('@/views/DeviceOversee/history.vue'),
        meta: { title: '歷史紀錄', logo: false }
      },
      {
        path: '/device-oversee-energy',
        name: 'device-energy',
        component: () => import('@/views/DeviceOversee/energy.vue'),
        meta: { title: '用電監測', logo: false }
      },
      {
        path: '/equip-oversee',
        name: 'equip',
        component: () => import('@/views/EquipOversee/index.vue'),
        meta: { title: '設備監管', logo: false }
      },
      {
        path: '/equip-oversee-list',
        name: 'equip-list',
        component: () => import('@/views/EquipOversee/equip.vue'),
        meta: { title: '主機列表', logo: false }
      },
      {
        path: '/equip-oversee-detail',
        name: 'equip-detail',
        component: () => import('@/views/EquipOversee/detail.vue'),
        meta: { title: '設備資訊', logo: false }
      },
      {
        path: '/energy-consumption',
        name: 'energy-consumption',
        component: () => import('@/views/EnergyConsumption/index.vue'),
        meta: { title: '設備耗能', logo: false }
      },
      {
        path: '/fire-control',
        name: 'fire-control',
        component: () => import('@/views/FireControl/index.vue'),
        meta: { title: '消控室管理', logo: false }
      },
      {
        path: '/fire-control-detail',
        name: 'fire-control-detail',
        component: () => import('@/views/FireControl/detail.vue'),
        meta: { title: '消控室詳情', logo: false }
      },
      {
        path: '/monitor',
        name: 'monitor',
        component: () => import('@/views/Monitor/index.vue'),
        meta: { title: '即時影像', logo: false }
      },
      {
        path: '/monitor-detail',
        name: 'monitor-detail',
        component: () => import('@/views/Monitor/detail.vue'),
        meta: { title: '影像詳情', logo: false }
      },
      {
        path: '/real-time',
        name: 'real-time',
        component: () => import('@/views/RealTime/index.vue'),
        meta: { title: '實時監測', logo: false }
      },
      {
        path: '/real-time-detail',
        name: 'real-time-detail',
        component: () => import('@/views/RealTime/detail.vue'),
        meta: { title: '監測詳情', logo: false }
      },
      {
        path: '/real-time-data',
        name: 'real-time-data',
        component: () => import('@/views/RealTime/data.vue'),
        meta: { title: '監測數據', logo: false }
      },
      {
        path: '/inspection',
        name: 'inspection',
        component: () => import('@/views/Inspection/index.vue'),
        meta: { title: '巡查區域位置', logo: false }
      },
      {
        path: '/inspection-mission',
        name: 'inspection-mission',
        component: () => import('@/views/Inspection/mission.vue'),
        meta: { title: '巡查任務', logo: false }
      },
      {
        path: '/inspection-detail-finish',
        name: 'inspection-detail-finish',
        component: () => import('@/views/Inspection/detailFin.vue'),
        meta: { title: '巡查任務詳情-已完成', logo: false }
      },
      {
        path: '/inspection-detail-unfinish',
        name: 'inspection-detail-unfinish',
        component: () => import('@/views/Inspection/detailUnfin.vue'),
        meta: { title: '巡查任務詳情-未完成', logo: false }
      },
      {
        path: '/hidden-danger',
        name: 'hidden-danger',
        component: () => import('@/views/HiddenDanger/index.vue'),
        meta: { title: '隱患管理', logo: false }
      },
      {
        path: '/hidden-danger-record',
        name: 'hidden-danger-record',
        component: () => import('@/views/HiddenDanger/record.vue'),
        meta: { title: '隱患紀錄', logo: false }
      },
      {
        path: '/hidden-danger-detail',
        name: 'hidden-danger-detail',
        component: () => import('@/views/HiddenDanger/listDetail.vue'),
        meta: { title: '隱患詳情', logo: false }
      },
      {
        path: '/hidden-danger-report',
        name: 'hidden-danger-report',
        component: () => import('@/views/HiddenDanger/report.vue'),
        meta: { title: '隱患上報', logo: false }
      },
      {
        path: '/hidden-danger-auto',
        name: 'hidden-danger-auto',
        component: () => import('@/views/HiddenDanger/auto.vue'),
        meta: { title: '自主巡查', logo: false }
      },
      {
        path: '/hidden-danger-point',
        name: 'hidden-danger-point',
        component: () => import('@/views/HiddenDanger/point.vue'),
        meta: { title: '排查點巡查', logo: false }
      },
      {
        path: '/enterprise-info',
        name: 'enterprise-info',
        component: () => import('@/views/EnterpriseInfo/index.vue'),
        meta: { title: '企業資訊', logo: false }
      },
      {
        path: '/enterprise-detail',
        name: 'enterprise-detail',
        component: () => import('@/views/EnterpriseInfo/detail.vue'),
        meta: { title: '企業詳情', logo: false }
      },
      {
        path: '/enterprise-memberlist',
        name: 'enterprise-memberlist',
        component: () => import('@/views/EnterpriseInfo/memberList.vue'),
        meta: { title: '人員組織', logo: false }
      },
      {
        path: '/enterprise-member',
        name: 'enterprise-member',
        component: () => import('@/views/EnterpriseInfo/member.vue'),
        meta: { title: '人員', logo: false }
      },
      {
        path: '/enterprise-build',
        name: 'enterprise-build',
        component: () => import('@/views/EnterpriseInfo/build.vue'),
        meta: { title: '建築資訊', logo: false }
      },
      {
        path: '/enterprise-position',
        name: 'enterprise-position',
        component: () => import('@/views/EnterpriseInfo/position.vue'),
        meta: { title: '重點部位', logo: false }
      },
      {
        path: '/enterprise-position-detail',
        name: 'enterprise-position-detail',
        component: () => import('@/views/EnterpriseInfo/posdetail.vue'),
        meta: { title: '重點部位', logo: false }
      },
      {
        path: '/user-info',
        name: 'user-info',
        component: () => import('@/views/UserInfo/index.vue'),
        meta: { title: '個人中心', logo: false }
      },
      {
        path: '/user-info-buy',
        name: 'user-info-buy',
        component: () => import('@/views/UserInfo/buy.vue'),
        meta: { title: '方案選擇', logo: false }
      },
      {
        path: '/user-info-charge',
        name: 'user-info-charge',
        component: () => import('@/views/UserInfo/charge.vue'),
        meta: { title: '線上充值', logo: false }
      },
      {
        path: '/user-info-chargehistory',
        name: 'user-info-chargehistory',
        component: () => import('@/views/UserInfo/chargehistory.vue'),
        meta: { title: '充值紀錄', logo: false }
      },
      {
        path: '/user-info-bill',
        name: 'user-info-bill',
        component: () => import('@/views/UserInfo/bill.vue'),
        meta: { title: '結算帳單', logo: false }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
